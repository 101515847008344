/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { trackUserId, trackScreenViewEvent } from '@avita-co-jp/frontend-utils';
import { createChannel } from '../../../../firebase';
import { signInByCustomToken } from '../../../../firebase/auth';
import { useServices } from '../../../../service';
import { appState, chatbotIdentityState } from '../../../../states';
import { useMessageUtil } from '../../utils/message.utils';
import { TMessage } from '../../../../service/type';
import { INITIAL_MESSAGES } from '../../../../consts/setting';

export const useStartScenario = () => {
  const app = useRecoilValue(appState);
  const setIdentity = useSetRecoilState(chatbotIdentityState);
  const { signInService } = useServices();

  const { addMessage } = useMessageUtil();
  const startScenario = useCallback(async () => {
    try {
      const authResponse = await signInService.signIn({ apiKey: app.apiKey });
      await signInByCustomToken(authResponse.firebaseCustomToken);

      const channelId = await createChannel(
        String(authResponse.identity.projectId),
        authResponse.identity.firebaseUuid,
        app.scenarioId,
        app.chatKey,
      );
      trackUserId(authResponse.identity.firebaseUuid);

      trackScreenViewEvent('start_chat');
      setIdentity({ identity: authResponse.identity, channelId });
      await addMessage(INITIAL_MESSAGES as TMessage[], authResponse.identity.projectId, channelId);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [addMessage, app.apiKey, app.chatKey, app.scenarioId, setIdentity, signInService]);
  return { startScenario };
};
