import { IChatContext } from '../ChatProvider.types';
import { useHideChat } from './useHideChat';
import { useOpenaiChat } from './useOpenaiChat';
import { useRestartScenario } from './useRestartScenario';
import { useStartScenario } from './useStartScenario';

export const useChat = () => {
  const { startScenario } = useStartScenario();
  const { hideChat } = useHideChat();
  const { reset } = useRestartScenario();
  const { answerQuestion } = useOpenaiChat();

  const value: IChatContext = {
    startChat: startScenario,
    hideChat,
    reset,
    answerQuestion,
  };

  return { value };
};
