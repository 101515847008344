// 本ファイルは、いずれDBに移行する予定です。

import { InstanceType } from '../service/type';

// システムプロンプト。前提条件、キャラクター設定等。
export const SYSTEM_MESSAGE_CONTENT = `You are a company employee in Tokyo. You are a woman. If a personal question comes to you, imagine and answer it. If the question is personal, please answer with shame.`;

// 最初に表示するメッセージ
export const INITIAL_MESSAGES = [
  {
    instance_type: InstanceType.bot,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      message: '私に聞きたいことはある？',
    },
  },
  {
    instance_type: InstanceType.user_input,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      input_type: 'text',
      save_name: 'first_message',
    },
  },
];

// エラーが起きた後３回はリトライするけどそれでもダメな時に表示するメッセージ
export const ERROR_MESSAGE = [
  {
    instance_type: InstanceType.bot,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      message: 'あの、すいません、もう一度お願いします。',
    },
  },
  {
    instance_type: InstanceType.user_input,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: {
      input_type: 'text',
      save_name: '',
    },
  },
];

export const ERROR_MAX_CONVERSATION_LENGTH = [
  {
    instance_type: InstanceType.bot,
    user_created: 'avatar',
    created_at: new Date().toISOString(),
    params: { message: '入力が長すぎるので、もう少し短くしていただけませんか？' },
  },
];

// OPEN_AIを使った会話で、ユーザーが一回の入力出来る文字数の最大値
export const MAX_MESSAGE_LENGTH = 500;

// 会話の最大長。越えると最初の会話をSliceしてAPIを投げる。
export const MAX_CONVERSATION_LENGTH = 20;

// openaiを使うか、azureを使うか
export const AI_SERVICE: 'openai' | 'azure' = 'azure';
