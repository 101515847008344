/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useServices } from '../../../../service';
import { messageState } from '../../../../states';
import { useMessageUtil } from '../../utils/message.utils';
import { InstanceType, TMessage } from '../../../../service/type';
import {
  ERROR_MAX_CONVERSATION_LENGTH,
  ERROR_MESSAGE,
  MAX_CONVERSATION_LENGTH,
  MAX_MESSAGE_LENGTH,
  SYSTEM_MESSAGE_CONTENT,
} from '../../../../consts/setting';

export const useOpenaiChat = () => {
  const messages = useRecoilValue(messageState);
  const { openaiService } = useServices();

  const { addMessage, addUserMessage } = useMessageUtil();
  const answerQuestion = useCallback(
    async (params: { userMessage: string }) => {
      const { userMessage } = params;
      await addUserMessage(userMessage);

      if (userMessage.length > MAX_MESSAGE_LENGTH) {
        await addMessage(ERROR_MAX_CONVERSATION_LENGTH as TMessage[]);
      } else {
        const requestParams = messages
          .filter(
            (message) =>
              message.instance_type === InstanceType.text_message || message.instance_type === InstanceType.bot,
          )
          .map((message) => {
            if (message.instance_type === InstanceType.text_message) {
              return { role: 'user', content: message.params.display_text };
            }
            if (message.instance_type === InstanceType.bot) {
              return { role: 'assistant', content: message.params.message };
            }
            return { role: 'user', content: '' };
          });
        requestParams.push({ role: 'user', content: userMessage });

        if (requestParams.length > MAX_CONVERSATION_LENGTH)
          requestParams.slice(requestParams.length - 20, requestParams.length);
        try {
          const response = await openaiService.fetchQuestionAnswer(requestParams, SYSTEM_MESSAGE_CONTENT);
          const message = { ...response, user_created: 'avatar' };
          await addMessage([message]);
        } catch (e) {
          await addMessage(ERROR_MESSAGE as TMessage[]);
        }
      }
    },
    [addMessage, addUserMessage, messages, openaiService],
  );

  return { answerQuestion };
};
