import { AI_SERVICE } from '../../consts/setting';
import { TMessage } from '../type';

type TParam = {
  role: string;
  content: string;
};

// eslint-disable-next-line no-promise-executor-return
const sleep = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));

export async function fetchQuestionAnswer(
  params: TParam[],
  systemMessageContent: string,
  retryCount = 3,
): Promise<TMessage> {
  const endpoint =
    AI_SERVICE === 'openai'
      ? `${process.env.NEXT_PUBLIC_OPENAI_ENDPOINT || 'http://localhost:3001'}/api/openai`
      : `${process.env.NEXT_PUBLIC_OPENAI_ENDPOINT || 'http://localhost:3001'}/api/openai/azure`;
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ messages: params, systemMessageContent }),
  });

  if (!response.ok) {
    if (retryCount <= 0) {
      throw new Error('回答の取得に失敗しました。');
    }
    await sleep(3000);
    const responseJson = await fetchQuestionAnswer(params, systemMessageContent, retryCount - 1);
    return responseJson;
  }

  return (await response.json()) as TMessage;
}
