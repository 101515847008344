import { send } from './email';
import { signIn } from './signIn';
import { fetchQuestionAnswer } from './openai';

export const useServices = () => {
  const openaiService = { fetchQuestionAnswer };
  const emailService = { send };
  const signInService = { signIn };

  return {
    emailService,
    signInService,
    openaiService,
  };
};
