/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { createChannel, resetRank } from '../../../../firebase';
import { appState, chatbotIdentityState } from '../../../../states';
import { useMessageUtil } from '../../utils/message.utils';
import { TMessage } from '../../../../service/type';
import { INITIAL_MESSAGES } from '../../../../consts/setting';

export const useRestartScenario = () => {
  const app = useRecoilValue(appState);
  const { identity } = useRecoilValue(chatbotIdentityState);
  const setIdentity = useSetRecoilState(chatbotIdentityState);

  const { addMessage } = useMessageUtil();
  const reset = useCallback(async () => {
    try {
      const channelId = await createChannel(
        String(identity.projectId),
        identity.firebaseUuid,
        app.scenarioId,
        app.chatKey,
      );
      setIdentity((prev) => ({ ...prev, channelId }));
      resetRank();

      await addMessage(INITIAL_MESSAGES as TMessage[], identity.projectId, channelId);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [addMessage, app.chatKey, app.scenarioId, identity.firebaseUuid, identity.projectId, setIdentity]);
  return { reset };
};
